import React, { Component } from 'react'
import '../Accessibility.css'

class Plan extends Component {
  render() {
    return (
      <div>
        <div className="accessibility-content-container-header-text">
          <h1 className="accessibility-content-container-title color-primary">
            Multi-year Accessibility Plan (AODA) – Ontario
          </h1>
          <h5 className="accessibility-content-container-subtitle">
            Latest Update: March, 2024
          </h5>
        </div>
        <h3 className="accessibility-subheading color-primary">Purpose</h3>
        <p className="accessibility-paragraph">
          This accessibility plan outlines the strategy of Embassy Ingredients
          to prevent and remove barriers for people with disabilities and comply
          with the requirements of the{' '}
          <i>Integrated Accessibility Standards Regulation</i> under the{' '}
          <i>Accessibility for Ontarians with Disabilities Act, 2005.</i>
        </p>
        <h3 className="accessibility-subheading color-primary">
          Statement of Commitment
        </h3>
        <p className="accessibility-paragraph">
          Embassy Ingredients is committed to providing an accessible
          environment for all clients, employees, job applicants, suppliers, and
          visitors who may enter our premises, access our information, or use
          our products and services. As an organization, we respect and comply
          with the requirements of the Accessibility for Ontarians with
          Disabilities Act, 2005, and its associated regulations. We strive to
          provide an accessible and welcoming environment for everyone by
          identifying and removing barriers in our workplace and ensuring that
          new barriers are not created. The company ensures that persons with
          disabilities are provided with equal opportunities. We are committed
          to meeting the needs of individuals with disabilities in a timely and
          integrative manner that respects their dignity and independence.
        </p>
        <p className="accessibility-paragraph">
          This plan is in effect from March 2024 to March 2029.
        </p>
        <p className="accessibility-paragraph">
          If you have any questions or concerns about this plan or its
          initiatives, or if you want to receive a copy of the plan in a
          different accessible format, please contact People & Development at
          <br />
          <strong>
            Email:{' '}
            <a href="mailto:peopleanddevelopment@embassyingredients.com">
              peopleanddevelopment@embassyingredients.com
            </a>
          </strong>{' '}
          <br />
          <strong>Work Phone: 905-789-3200 x 230</strong>
        </p>
        <h3 className="accessibility-subheading color-primary">General</h3>
        <p className="accessibility-paragraph">
          Embassy is dedicated to ensuring accessibility for individuals with
          disabilities. The company has allocated substantial resources towards
          enhancing the usability and accessibility of its website, recognizing
          that such efforts benefit all users. Embassy firmly upholds the belief
          that every individual has the inherent right to live with dignity,
          equality, comfort, and independence.
        </p>
        <h3 className="accessibility-subheading color-primary">
          Information and Communication Standards
        </h3>
        <p className="accessibility-paragraph">
          Embassy remains committed in ensuring that company information and
          communications are accessible to individuals with disabilities.
        </p>
        <p className="accessibility-paragraph">
          Regarding websites and web content, we will diligently monitor the
          development of any new websites and content, including those
          undergoing significant updates, to ensure compliance with AODA
          standards. We will also establish and uphold processes to verify
          ongoing compliance with the required WCAG conformance levels.
        </p>
        <p className="accessibility-paragraph">
          For general feedback, accessible formats, and communication support,
          our feedback procedures are designed to be accessible. We readily
          provide or arrange accessible formats and communication support upon
          request, at no additional cost and in a timely manner. Requesting
          individuals will have the opportunity to provide input on the
          suitability of the accessible format or communication support.
          Feedback can be submitted to People & Development either by phone or
          in writing via email.
        </p>
        <p className="accessibility-paragraph">
          Feedback can be submitted to:
          <br />
          <strong>
            Email:{' '}
            <a href="mailto:peopleanddevelopment@embassyingredients.com">
              peopleanddevelopment@embassyingredients.com
            </a>
          </strong>{' '}
          <br />
          <strong>Work Phone: 905-789-3200 x 230</strong>
        </p>
        <p className="accessibility-paragraph">
          Notification about the availability of accessible formats and
          communication support will be prominently displayed on our website,{' '}
          <a
            href="https://www.embassyingredients.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.embassyingredients.com.
          </a>
        </p>
        <h3 className="accessibility-subheading color-primary">
          Employment Standards
        </h3>
        <p className="accessibility-paragraph">
          Embassy is dedicated to fostering accessible employment practices and
          eliminating barriers that may hinder the recruitment, retention, and
          career progression of employees with disabilities. Our commitment to
          accessibility is deeply rooted in our employment-related processes,
          and we have implemented the following initiatives:
        </p>
        <h4 style={{ marginBottom: '5px' }} className="underline">
          Emergency Response Plans:
        </h4>
        <p className="accessibility-paragraph">
          We create alternative or individual emergency preparedness plans for
          employees with disabilities, depending on their individual needs.
        </p>
        <h4 style={{ marginBottom: '5px' }} className="underline">
          Recruitment:
        </h4>
        <p className="accessibility-paragraph">
          We notify job applicants that accommodation is available upon request
          during the recruitment process, as indicated in job postings and in
          our recruitment policies. Accommodation availability is also
          communicated in recruitment materials and during interview and
          assessment scheduling. Upon request, we consult with applicants to
          provide suitable accommodation tailored to their disability-related
          needs. Successful applicants are informed of our disability
          accommodation policies upon employment offer.
        </p>
        <h4 style={{ marginBottom: '5px' }} className="underline">
          Training:
        </h4>
        <p className="accessibility-paragraph">
          We provide comprehensive training to employees involved in
          recruitment, assessment, selection, and onboarding to ensure effective
          delivery of planned actions and fulfillment of accommodation requests.
        </p>
        <h4 style={{ marginBottom: '5px' }} className="underline">
          Workplace:
        </h4>
        <p className="accessibility-paragraph">
          We adapt existing orientation and onboarding processes to inform new
          employees about our accessibility policies. We establish procedures
          for documenting and updating individual accommodation plans, ensuring
          involvement of requesting employees in plan development, and providing
          plans in accessible formats. Leaders and team members responsible for
          supporting accommodation plans receive appropriate training to ensure
          ongoing success.
        </p>
        <h4 style={{ marginBottom: '5px' }} className="underline">
          Return to Work:
        </h4>
        <p className="accessibility-paragraph">
          We review and modify return-to-work processes for employees requiring
          accommodation due to disability-related absences, incorporating
          documented individual accommodation plans into the process. Leaders
          and employees involved in supporting return-to-work processes receive
          training to ensure effective implementation.
        </p>
        <h4 style={{ marginBottom: '5px' }} className="underline">
          Performance Management:
        </h4>
        <p className="accessibility-paragraph">
          We consider the accessibility needs of employees with disabilities in
          providing career development opportunities, including notifying them
          of accommodation availability for internal job postings.
        </p>
        <h3 className="accessibility-subheading color-primary">
          Customer Service Standards
        </h3>
        <p className="accessibility-paragraph" style={{ marginBottom: '0' }}>
          Embassy adheres to the Accessible Customer Service Regulation outlined
          in the Accessibility for Ontarians with Disabilities Act (AODA). Our
          compliance involves several key measures:
        </p>
        <ol>
          <li>
            Comprehensive training is provided to all individuals interfacing
            with the public on behalf of the company, as well as those involved
            in policy development related to customer service. This training
            encompasses understanding provincial accessibility standards and
            requirements, effective communication and interaction techniques,
            and supporting individuals with disabilities while considering their
            specific needs. Training occurs promptly upon hiring and is
            recurrent to align with any updates to our policies and procedures.
          </li>
          <li>
            Individuals with disabilities can use their personal assistive
            devices to access and benefit from our goods and services.
          </li>
          <li>
            When a person with a disability is accompanied by a support person,
            both individuals receive equitable access to our goods and services.
          </li>
          <li>
            We provide public notice regarding any temporary or permanent
            disruptions to facilities or services typically utilized by
            individuals with disabilities. This includes details on the reason
            for the disruption, expected duration, and alternative facilities or
            services available.
          </li>
          <li>
            We encourage feedback on our delivery of goods and services to
            individuals with disabilities through various communication
            channels.
          </li>
        </ol>
        <p className="accessibility-paragraph">
          These measures collectively ensure our commitment to providing
          accessible and inclusive services to all individuals, regardless of
          disability status.
        </p>
        <h3 className="accessibility-subheading color-primary">Disclaimer</h3>
        <p className="accessibility-paragraph">
          Embassy is committed to the ongoing enhancement of accessibility for
          its website and services. We believe it is our collective moral
          responsibility to ensure that individuals with disabilities can
          utilize our site seamlessly and without hindrance. As part of our
          continuous improvement efforts, we conduct regular scans of
          embassyingredients.com to identify and address any accessibility
          barriers. While we strive to make all content on our website fully
          accessible, there may be instances where certain content has not yet
          been fully aligned with the most rigorous accessibility standards.
          This may occur due to challenges in finding or implementing the most
          suitable technological solutions.
        </p>
      </div>
    )
  }
}

export default Plan
