import React, { Component } from 'react'
import '../Accessibility.css'

class Policy extends Component {
  render() {
    return (
      <div>
        <div className="accessibility-content-container-header-text">
          <h1 className="accessibility-content-container-title color-primary">
            Accessibility For Ontarians with Disabilities Act (AODA) Policy
          </h1>
          <h5 className="accessibility-content-container-subtitle">
            Latest Update: March, 2024
          </h5>
        </div>
        <p className="accessibility-paragraph">
          Embassy Ingredients is committed to ensuring fair access and inclusion
          for individuals with disabilities, treating them with dignity and
          autonomy. We advocate for integration and promptly addressing the
          needs of individuals with disabilities. We actively remove and prevent
          accessibility barriers, strictly following the Accessibility for
          Ontarians with Disabilities Act (AODA) and other relevant Ontario
          accessibility laws. Our commitment extends to meeting current and
          future obligations under the Ontario Human Rights Code, respecting
          non-discrimination.
        </p>
        <p className="accessibility-paragraph">
          We understand that AODA requirements don't replace or limit our
          obligations under the Ontario Human Rights Code or responsibilities to
          people with disabilities under any other law. We are dedicated to
          excellence in serving all customers, including people with
          disabilities, following the principles of independence, dignity,
          integrity, and equality of opportunity.
        </p>
        <h3 className="accessibility-subheading color-primary">Definitions</h3>
        <p className="accessibility-paragraph">
          <span className="underline">Accessible formats:</span> Include but are
          not limited to large print, recorded audio and electronic formats,
          braille, and other formats usable by persons with disabilities.
        </p>
        <p className="accessibility-paragraph">
          <span className="underline">Assistive device:</span> Any medical
          device, mobility aid, communication aid, or other aid that is
          specially designed to assist a person with a disability with a need
          related to their disability.
        </p>
        <p className="accessibility-paragraph">
          <span className="underline">Communication supports:</span>
          Captioning, alternative and augmentative communication supports, plain
          language, sign language, and other supports that facilitate effective
          communications.
        </p>
        <p className="accessibility-paragraph">
          <span className="underline">Constructive discrimination:</span> Also
          referred to as adverse effect, is discrimination that unintentionally
          singles out a particular group, resulting in unequal treatment.
        </p>
        <p className="accessibility-paragraph">
          <span className="underline">Conversion-ready:</span>
          An electronic or digital format that facilitates conversion into an
          acceptable format.
        </p>
        <p className="accessibility-paragraph">
          <span className="underline">Disability:</span> Any impairment,
          including a physical, mental, intellectual, cognitive, learning,
          communication, and sensory impairment, or functional limitation that
          is either permanent, temporary, or episodic in nature. It can be
          evident or not in interaction with a barrier to hinder a person’s full
          and equal participation in society.
        </p>
        <p className="accessibility-paragraph">
          <span className="underline">Mobility aid:</span> Any manual or
          electric wheelchair, scooter, boarding chair, walker, cane, crutch,
          prosthesis, or other aid that is specially designed to assist a person
          with a disability with a need related to mobility.
        </p>
        <p className="accessibility-paragraph">
          <span className="underline">Service animal:</span> An animal that can
          be recognized by visual indicators such as a vest or harness that is
          being used by an individual with a disability for reasons relating to
          the person’s disability.
        </p>
        <p className="accessibility-paragraph">
          <span className="underline">Support person:</span> Another person who
          accompanies an individual with a disability to help with
          communication, mobility, personal care, medical needs, or access to
          goods and services.
        </p>
        <h3 className="accessibility-subheading color-primary">Training</h3>
        <p className="accessibility-paragraph">
          We ensure thorough training for employees and volunteers (if
          applicable), including people engaged in creating organizational
          policies and providing goods, services or facilities on the behalf of
          Embassy ingredients, covering accessible customer service, Ontario's
          accessibility standards, and relevant parts of the Ontario Human
          Rights Code related to individuals with disabilities.
        </p>
        <p className="accessibility-paragraph" style={{ marginBottom: '0' }}>
          The training is customized to their roles, including:
        </p>
        <ol>
          <li>
            Understanding the Accessibility for Ontarians with Disabilities Act,
            2005, and compliance with Customer Service Standards.
          </li>
          <li>
            Aligning with our policies and the Customer Service Standards.
          </li>
          <li>
            Interacting effectively and communicating with people with various
            disabilities.
          </li>
          <li>
            Properly engaging with those using assistive devices, service
            animals, or support persons.
          </li>
          <li>
            Proficiently using available equipment for serving individuals with
            disabilities.
          </li>
          <li>
            Knowing what to do if someone with a disability faces difficulty
            accessing our goods, services, or facilities.
          </li>
        </ol>
        <p className="accessibility-paragraph">
          We provide specific training for new employees when required and
          promptly update existing employees on policy changes. Detailed
          records, including dates and the number of individuals trained, are
          maintained.
        </p>
        <h3 className="accessibility-subheading color-primary">
          Emergency Response Plan
        </h3>
        <p className="accessibility-paragraph">
          As deemed necessary or at the request of individuals, Embassy
          Ingredients is committed to developing personalized workplace
          emergency response plans for employees with disabilities. These plans
          are crafted, considering the distinct challenges posed by each
          individual's disability and the company’s physical characteristics.
          The process involves collaborative consultation with the employees
          involved. If an employee with a disability requires assistance during
          an emergency, we identify a fellow employee, agreed upon by both
          parties, to fulfill that role. If necessary, the designated employee
          has the required first aid training and certification for emergency
          support.
        </p>
        <p className="accessibility-paragraph" style={{ marginBottom: '0' }}>
          Individualized emergency response plans undergo reviews under the
          following circumstances:
        </p>
        <ol>
          <li>
            When an employee relocates to a different physical location within
            the organization.
          </li>
          <li>
            As part of the overall assessment of the employee's accommodation
            needs or plans.
          </li>
          <li>
            During the periodic review of the company's overarching emergency
            response policies.
          </li>
        </ol>
        <h3 className="accessibility-subheading color-primary">
          Return to Work
        </h3>
        <p className="accessibility-paragraph">
          Embassy Ingredients provides a thorough return-to-work program for
          employees on disability-related leave, following guidance from medical
          professionals. Working closely with the employee, we create a
          personalized return-to-work plan and actively support the transition,
          identifying and addressing any obstacles. The process outlines the
          steps we'll take to facilitate the employee's reintegration and meet
          specific accommodation needs.
        </p>
        <h3 className="accessibility-subheading color-primary">Redeployment</h3>
        <p className="accessibility-paragraph">
          If an employee, despite accommodation, cannot be effectively supported
          in their current role, Embassy ingredients will explore redeployment
          options. This means finding the employee an alternative position
          within the company, either temporarily or permanently, depending on
          their needs. People & Development will collaborate with the employees
          to assess available positions that can accommodate their requirements.
          If significant changes are needed, like alterations to duties or
          hours, we may choose to redesign the position accordingly.
        </p>
        <h3 className="accessibility-subheading color-primary">
          Inability to Accommodate
        </h3>
        <p className="accessibility-paragraph">
          Embassy Ingredients provides workplace accommodations up to the point
          of undue hardship. Undue hardship may occur if no reasonable
          accommodation is feasible, or if implementing it poses health and
          safety risks.
        </p>
        <p className="accessibility-paragraph">
          If a necessary accommodation is deemed to cause undue hardship, we are
          committed to finding a fair compromise that effectively meets both the
          employee's needs and the organizational constraints to the best extent
          possible.
        </p>
        <h3 className="accessibility-subheading color-primary">Employment</h3>
        <p className="accessibility-paragraph">
          Embassy Ingredients actively works to remove obstacles and create
          inclusive practices for individuals with disabilities in both hiring
          and employment. If a candidate needs accommodation during the
          recruitment process or a current employee requires workplace support,
          we collaborate with them to provide necessary accommodation, ensuring
          it's done up to the point where it becomes too burdensome.
        </p>
        <h4 className="underline">Job Design</h4>
        <p className="accessibility-paragraph" style={{ marginBottom: '0' }}>
          Embassy Ingredients conducts thorough job analyses to validate new and
          existing job requirements, considering them bona fide if reasonable
          and made in good faith. We use specific criteria to determine the
          legitimacy of a requirement:
        </p>
        <ol>
          <li>It's logically connected to effective job performance.</li>
          <li>
            It's implemented in good faith and serves a legitimate work-related
            purpose.
          </li>
          <li>It's reasonably necessary for a work-related process or task.</li>
        </ol>
        <p className="accessibility-paragraph">
          If a requirement is found not to be bona fide, we assess if reasonable
          accommodations can be provided for equal opportunities. We are
          committed to non-discriminatory job design, actively evaluating jobs
          to prevent potential instances of constructive discrimination.
        </p>
        <h4 className="underline">Hiring and Recruitment</h4>
        <p className="accessibility-paragraph">
          Embassy Ingredients conducts recruitment and selection with a
          commitment to dignity and inclusion. We gladly provide reasonable
          accommodations for candidates upon request during interviews,
          collaborating to arrange suitable accommodations like alternative
          formats.
        </p>
        <p className="accessibility-paragraph">
          Highlighting fairness, our hiring decisions are based on
          qualifications and experience, free from bias. The interview process
          assesses skills and experiences, avoiding discrimination against
          individuals with disabilities or those needing accommodations, both in
          interviews and potential future employment. Successful candidates are
          informed of policies and available support for accommodation at the
          end of the recruitment process.
        </p>
        <h3 className="accessibility-subheading color-primary">
          Customer Service
        </h3>
        <h4 className="underline">Access to Goods and Services</h4>
        <p className="accessibility-paragraph">
          Embassy Ingredients is committed to ensuring that all customers have
          barrier-free access to the company's goods and services. In cases
          where it is not possible to eliminate barriers, the company strives to
          offer alternative means for accessing goods or services to the best of
          its ability.
        </p>
        <h4 className="underline">Support Persons</h4>
        <p className="accessibility-paragraph">
          Embassy Ingredients welcomes customers with disabilities and their
          support persons, ensuring they can enter the premises together without
          any hindrance. We make efforts to arrange seating for the customer and
          their support person to sit together. In situations involving
          confidential information, we seek the customer's consent before
          sharing such information in the presence of their support person.
        </p>
        <p className="accessibility-paragraph">
          Under specific circumstances, Embassy Ingredients may be deemed
          necessary for an individual with a disability to be accompanied by a
          support person due to health or safety considerations. This decision
          is made with careful deliberation, considering the well-being of the
          person with a disability and others present on the premises.
        </p>
        <p className="accessibility-paragraph" style={{ marginBottom: '0' }}>
          Before reaching a conclusion, our organization will:
        </p>
        <ol>
          <li>
            Engage in consultations with the individual with a disability to
            gain a thorough understanding of their specific needs.
          </li>
          <li>
            Evaluate health or safety concerns based on available evidence.
          </li>
          <li>
            Determine whether there are no other reasonable alternatives to
            safeguard the health and safety of the person in question or others
            on the premises.
          </li>
        </ol>
        <h4 className="underline">Service Animals</h4>
        <p className="accessibility-paragraph">
          Service animals are prohibited to enter the areas of food production,
          including the R&D flavor and bakery Labs under The Health Protection
          and Promotion Act, Ontario Regulation 60, which does not allow animals
          in places where food is manufactured, prepared, processed, handled,
          served, displayed, stored, sold, or offered for sale.
        </p>
        <h4 className="underline">Assistive Devices</h4>
        <p className="accessibility-paragraph">
          Individuals with disabilities are encouraged to use their personal
          assistive devices when interacting with our products, services, or
          facilities. If there are significant health or safety concerns or
          valid reasons prohibiting the use of such devices, we will implement
          alternative measures to ensure accessibility for individuals with
          disabilities.
        </p>
        <p className="accessibility-paragraph">
          Our staff undergoes training to familiarize themselves with a variety
          of assistive devices. This ensures that they are knowledgeable about
          the tools customers with disabilities may use when accessing our
          goods, services, or facilities.
        </p>
        <h4 className="underline">Communication</h4>
        <p className="accessibility-paragraph">
          Embassy Ingredients values accessibility in both digital and
          non-digital communication for individuals with disabilities. We
          provide accessible communication and information, available promptly
          and without extra charges upon request.
        </p>
        <p className="accessibility-paragraph">
          If someone requests accessible formats or communication support, we'll
          consult with them to assess the most suitable options. If, after
          evaluation, certain information can't be converted, we'll provide a
          clear explanation of why and a concise summary of the unconvertible
          content.
        </p>
        <p className="accessibility-paragraph">
          Additionally, our commitment extends to meeting the website
          accessibility requirements outlined by Ontario's accessibility laws,
          adhering to the internationally recognized Web Content Accessibility
          Guidelines (WCAG) 2.0 Level AA.
        </p>
        <h4 className="underline">Service Disruptions</h4>
        <p className="accessibility-paragraph">
          Service disruptions, whether within or beyond the control or awareness
          of Embassy Ingredients, may occur. If there's a temporary disruption
          affecting facilities or services crucial for customers with
          disabilities, we're committed to giving advance notice whenever
          possible.
        </p>
        <p className="accessibility-paragraph" style={{ marginBottom: '0' }}>
          During disruptions, Embassy Ingredients will take the following
          measures:
        </p>
        <ol>
          <li>
            Post notices at the closest accessible entrance to communicate the
            service disruption.
          </li>
          <li>
            Update the company website with relevant information about the
            disruption.
          </li>
          <li>
            Contact customers with reservations or appointments using any
            reasonable method available under the circumstances.
          </li>
        </ol>
        <p className="accessibility-paragraph">
          We make every effort to estimate when services will resume and suggest
          alternative options during the disruption. However, we acknowledge
          that in some situations, like unplanned disruptions or emergencies,
          giving advance notice may not be possible.
        </p>
        <h4 className="underline">Emergency Notifications</h4>
        <p className="accessibility-paragraph">
          Embassy Ingredients ensures that emergency and public safety
          information, plans, procedures, maps, and warning signs are
          accessible. We provide them in accessible formats or with
          communication support on request. The alarm systems implemented by the
          company are designed to be auditory. However, accommodation can be
          made if required by the employee under the individual emergency
          response plan.
        </p>
        <p className="accessibility-paragraph" style={{ marginBottom: '0' }}>
          Our commitment includes:
        </p>
        <ol>
          <li>
            Working with individuals to address their specific needs when
            requesting information.
          </li>
          <li>
            Making sure that emergency information is clear and understandable
            for everyone, including those with disabilities.
          </li>
          <li>
            Ensuring that in emergencies, if someone with a disability needs
            assistance, our staff is available to provide the necessary support.
          </li>
        </ol>
        <h4 className="underline">Feedback</h4>
        <p className="accessibility-paragraph">
          Embassy Ingredients values feedback from customers and employees to
          improve service quality, attract more clients, reduce complaints,
          enhance working conditions, and promote an inclusive workplace,
          especially in terms of accessibility. We make it easy for individuals
          with disabilities to provide feedback through various methods,
          including accessible options upon request.
        </p>
        <p className="accessibility-paragraph">
          Those providing formal feedback receive acknowledgment, along with
          details about any actions taken in response to their concerns or
          complaints. This commitment ensures transparency and accountability in
          addressing feedback, leading to continuous improvement in our services
          and accessibility practices.
        </p>
        <p className="accessibility-paragraph">
          Feedback can be submitted to:
          <br />
          <strong>
            Email:{' '}
            <a href="mailto:peopleanddevelopment@embassyingredients.com">
              peopleanddevelopment@embassyingredients.com
            </a>
          </strong>
          <br />
          <strong> Work Phone: 905-789-3200 x 230</strong>
        </p>
        <p className="accessibility-paragraph">
          Embassy Ingredients respects the privacy and preferences of feedback
          providers. We accept anonymous feedback, keeping it confidential
          unless the person agrees to disclose their personal information. We
          accommodate feedback in any comfortable format chosen by the
          individual.
        </p>
        <p className="accessibility-paragraph">
          All personal information shared is treated with utmost
          confidentiality, following privacy laws. This ensures individuals feel
          secure sharing feedback for ongoing improvements in our services and
          accessibility.
        </p>
        <p className="accessibility-paragraph">
          Customers can expect a response within 5 business days of submitting
          complaints.
        </p>
        <h4 className="underline">Building Accessibility</h4>
        <p className="accessibility-paragraph">
          Embassy Ingredients is committed to creating an infrastructure that
          allows easy access for both customers and employees, both inside and
          outside. If there are areas that aren't accessible for individuals
          with disabilities, we work with them to find alternative ways to
          ensure access, considering accessibility up to a reasonable limit.
        </p>
        <h4 className="underline">Disclaimer</h4>
        <p className="accessibility-paragraph">
          Embassy is committed to the ongoing enhancement of accessibility for
          its website and services. We believe it is our collective moral
          responsibility to ensure that individuals with disabilities can
          utilize our site seamlessly and without hindrance. As part of our
          continuous improvement efforts, we conduct regular scans of
          embassyingredients.com to identify and address any accessibility
          barriers. While we strive to make all content on our website fully
          accessible, there may be instances where certain content has not yet
          been fully aligned with the most rigorous accessibility standards.
          This may occur due to challenges in finding or implementing the most
          suitable technological solutions.
        </p>
      </div>
    )
  }
}

export default Policy
